// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getStatus = createAsyncThunk('report/getStatus', async () => {
  const response = await axios.get('/reports/status')
  return response.data
})

export const addReport = createAsyncThunk(
  'data/addInventory',
  async (report, { dispatch }) => {
    const response = await axios.post('/reports', report)
    await dispatch(getStatus())
    return response.data
  }
)

export const getExport = createAsyncThunk('report/getExport', async (report) => {
  const response = await axios.get(`/reports/export/${report}`)
  return response.data
})

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    status: null,
    exports: []
  },
  extraReducers: {
    [getStatus.pending]: (state) => {
      state.isLoading = true
    },
    [getStatus.fulfilled]: (state, action) => {
      state.isLoading = false
      state.status = action.payload
    },
    [addReport.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Laporan telah tersimpan',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [addReport.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Please check input',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [getExport.pending]: (state) => {
      state.isLoading = true
    },
    [getExport.fulfilled]: (state, action) => {
      state.isLoading = false
      state.exports = action.payload
    }
  }
})

export default reportSlice.reducer
