// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import data from '@src/views/data/store'
import report from '@src/views/laporan/store'
import user from '@src/views/users/store'
import personel from '@src/views/public/personel/store'

const rootReducer = {
  auth,
  data,
  report,
  user,
  personel,
  navbar,
  layout
}

export default rootReducer
