// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getPersonil = createAsyncThunk('data/getPersonil', async () => {
  const response = await axios.get('/users/personil') //ganti dg api yg filter tasks berdasarkan board_id dan belum dibuat di tabel report
  return response.data
})

export const getInventories = createAsyncThunk('data/getInventaris', async () => {
  const response = await axios.get('/inventories') //ganti dg api yg filter tasks berdasarkan board_id dan belum dibuat di tabel report
  return response.data
})

export const getStatus = createAsyncThunk('data/getStatus', async () => {
  const response = await axios.get('/inventories/status') //ganti dg api yg filter tasks berdasarkan board_id dan belum dibuat di tabel report
  return response.data
})

export const addReport = createAsyncThunk(
  'data/addReport',
  async (reports, { dispatch }) => {
    const response = await axios.post('/inventories/reports', { inventories: reports })
    await dispatch(getInventories())
    await dispatch(getStatus())
    return response.data
  }
)

export const addInventory = createAsyncThunk(
  'data/addInventory',
  async (inventory, { dispatch }) => {
    const response = await axios.post('/inventories', inventory)
    await dispatch(getInventories())
    await dispatch(getStatus())
    return response.data
  }
)

export const updateInventory = createAsyncThunk(
  'data/updateInventory',
  async (inventory, { dispatch }) => {
    const response = await axios.put(`/inventories/${inventory.id}`, inventory)
    await dispatch(getInventories())
    await dispatch(getStatus())
    return response.data
  }
)

export const deleteInventory = createAsyncThunk('data/deleteInventory', async ({ id }, { dispatch }) => {
  const response = await axios.delete(`/inventories/${id}`)
  await dispatch(getInventories())
  await dispatch(getStatus())
  return response.data
})

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    users: [],
    inventories: [],
    status: null,
    selected: null,
    isLoading: false
  },
  reducers: {
    selectInventaris: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: {
    [getPersonil.pending]: (state) => {
      state.isLoading = true
    },
    [getPersonil.fulfilled]: (state, action) => {
      state.isLoading = false
      state.users = action.payload
    },
    [getInventories.pending]: (state) => {
      state.isLoading = true
    },
    [getInventories.fulfilled]: (state, action) => {
      state.isLoading = false
      state.inventories = action.payload
      state.status = state.inventories.every((inven) => Number(inven.condition) > 0)
    },
    [getStatus.pending]: (state) => {
      state.isLoading = true
    },
    [getStatus.fulfilled]: (state, action) => {
      state.isLoading = false
      state.status = action.payload.status
    },
    [addReport.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Laporan telah tersimpan',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [addReport.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Please check input',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [addInventory.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Inventaris telah ditambahkan',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [addInventory.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Please check input',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [updateInventory.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Inventaris telah diperbaharui',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [updateInventory.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: 'Please check input',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [deleteInventory.fulfilled]: () => {
      MySwal.fire({
        title: 'Success!',
        text: 'Inventaris telah dihapus',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    [deleteInventory.rejected]: () => {
      MySwal.fire({
        title: 'Failed!',
        text: '',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    }
  }
})
export const { selectInventaris } = dataSlice.actions
export default dataSlice.reducer
