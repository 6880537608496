// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const getPersonels = createAsyncThunk('personel/getPersonels', async () => {
  const response = await axios.get('/personels') //ganti dg api yg filter tasks berdasarkan board_id dan belum dibuat di tabel report
  return response.data
})


export const personelSlice = createSlice({
  name: 'personel',
  initialState: {
    personels: [],
    isLoading: false
  },
  extraReducers: {
    [getPersonels.pending]: (state) => {
      state.isLoading = true
    },
    [getPersonels.fulfilled]: (state, action) => {
      state.isLoading = false
      state.personels = action.payload
    }
  }
})
export default personelSlice.reducer
