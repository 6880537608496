// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// ** Axios Imports
import axios from 'axios'

export const getAllUser = createAsyncThunk('user/getAllUser', async () => {
    const response = await axios.get('/users')
    return response.data
})

export const getAllRoles = createAsyncThunk('user/getAllRoles', async () => {
    const response = await axios.get('/users/roles')
    return response.data
})

export const getMe = createAsyncThunk('user/getMe', async () => {
    const response = await axios.get('/users/me')
    return response.data
})

export const getUser = createAsyncThunk('user/getUser', async (id) => {
    const response = await axios.get(`/users/${id}`)
    return response.data
})

export const addUser = createAsyncThunk(
    'user/addUser',
    async (user, { dispatch }) => {
        const response = await axios.post('/users', user)
        await dispatch(getAllUser())
        return response.data
    }
)

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (user, { dispatch }) => {
        const response = await axios.put(`/users/${user.id}`, user)
        await dispatch(getAllUser())
        return response.data
    }
)

export const updateMyUser = createAsyncThunk(
    'user/updateMyUser',
    async (user, { dispatch }) => {
        const response = await axios.patch(`/users`, user)
        await dispatch(getMe())
        return response.data
    }
)

export const updateMyPassword = createAsyncThunk(
    'user/updateMyPassword',
    async (user, { dispatch }) => {
        const response = await axios.patch(`/users/password`, user)
        await dispatch(getMe())
        return response.data
    }
)

export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async ({ id }, { dispatch }) => {
        const response = await axios.delete(`/users/${id}`)
        await dispatch(getAllUser())
        return response.data
    })


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        users: [],
        me: null,
        user: null,
        roleOptions: [],
        selected: null,
        isLoading: false
    },
    reducers: {
        selectUser: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: {
        [getAllUser.pending]: (state) => {
            state.isLoading = true
        },
        [getAllUser.fulfilled]: (state, action) => {
            state.isLoading = false
            state.users = action.payload
        },
        [getMe.pending]: (state) => {
            state.isLoading = true
        },
        [getMe.fulfilled]: (state, action) => {
            state.isLoading = false
            state.me = action.payload
        },
        [getUser.pending]: (state) => {
            state.isLoading = true
        },
        [getUser.fulfilled]: (state, action) => {
            state.isLoading = false
            state.user = action.payload
        },
        [getAllRoles.pending]: (state) => {
            state.isLoading = true
        },
        [getAllRoles.fulfilled]: (state, action) => {
            state.isLoading = false
            state.roleOptions = []
            action.payload.map(item => {
                state.roleOptions.push({ value: item.id, label: item.title })
            })
        },
        [addUser.fulfilled]: () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Add user successful',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [addUser.rejected]: () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Add user is failed',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [updateUser.fulfilled]: () => {
          MySwal.fire({
            title: 'Success!',
            text: 'update user successful',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [updateUser.rejected]: () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'update user is failed',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [updateMyUser.fulfilled]: () => {
          MySwal.fire({
            title: 'Success!',
            text: 'update user successful',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [updateMyUser.rejected]: () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'update user is failed',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [updateMyPassword.fulfilled]: () => {
          MySwal.fire({
            title: 'Success!',
            text: 'change password successful',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [updateMyPassword.rejected]: () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Please check your current password',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [deleteUser.fulfilled]: () => {
          MySwal.fire({
            title: 'Success!',
            text: 'Delete user successful',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        },
        [deleteUser.rejected]: () => {
          MySwal.fire({
            title: 'Failed!',
            text: 'Delete user is failed',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        }
    }
})
export const { selectUser } = userSlice.actions
export default userSlice.reducer
